<template>
  <div class="snp-primer">
    <wapper-component title="Welcome to SNP Primer" :background="Bg" />

    <div class="container">
      <h2>SNP Primer</h2>
      <p class="exp">
        SNPPrimer是SNP标记引物设计工具。
        根据提供序列、SNP基因型及位置信息，自动批量设计FLU-ARMS标记引物。
      </p>

      <p class="prompt">请输入序列文件：</p>
      <p class="prompt">1、SNP用单碱基[SNP1/SNP2]表示，并提供前后100bp的序列</p>
      <p class="prompt">
        2、可识别大小括弧，例如<span class="highlight-flag">[A/G]</span
        >,(A/G),{A/G}
      </p>
      <p class="prompt highlight">
        3、不识别缺失，多碱基，或局部连续多SNP。例如[-/ACC]，[-/G]，CT[A/G]CTGAT[T/C]。如有需要请致电固德生物咨询
      </p>
      <p class="prompt">
        4、批量引物设计以 > 字符识别下一段序列，例如
        <span class="prompt-li">
          <span class="prompt-t"
            ><span class="highlight-flag">></span>SNP29112616</span
          >
          <span class="prompt-c">
            TTACCCTCCCTTGAATTCATCACAGAAGCTTAATCAATTCATTTCAGAGTAGATGAAAGAGGTACACTAATCTGTTTTCATGTTGTAGTCCTTTGTTGGATTATATTTTCCTCAAGTCTCTTTCCACTGAACAAATTGAAGGGAATTTGTTCCATCCATCTCACAACACAAAGACAAAACAAAACAAAGGAATCAACAGACATAAA<span
              class="highlight-flag"
              >[A/G]</span
            >CAAATAAAAAAATGAACAACTTGCAAGAAACAAATCAATATTGATCGAACACGAGACAAATCAATCTAGACACAGAATCGAACACAACAAACAATCAATCTAGGCACAGAAGAAGCACAATCTAAGAAATACATCATTCAGGAACATTAAACATAAA
          </span>
        </span>

        <span class="prompt-li">
          <span class="prompt-t"
            ><span class="highlight-flag">></span>SNP29120636</span
          >
          <span class="prompt-c">
            ATAAATTCACTAACGTTGAAAGGTCTTTGGTCGCATCTTCTTTTGCTTTTTGTGGCTTCCCCGGTGACCGGTGCTCCTTACCGCCGCCGGGGTGGTTCTCTCATGTTATGCTCTTTCTTCTCTCTTTCCTTTGTCTTTTTTTTTGGGGTTTTACACATGATAATTTTCTATTCT<span
              class="highlight-flag"
              >[A/G]</span
            >CTGAGATCATTTGGCGATTGTAGTGATGGGTCGTGTTGGTGGGGCTTCGTTTGGCATGTCCGTGGTGTGGAGTCGCAACTGAGGCGTGAAGAGGGCCCATTCACAGGGGTAGCTCTTCTACCGGATAAGTGGTGCTTGTTTTGGCATTGTCGGGGGTGTGGAGTCTCACCGGAAGCACATGAAGAGCC
          </span>
        </span>
      </p>
      <el-divider />

      <p>
        序列文件文件：
        <b class="effect" @click="onEffect">示例序列文件</b>
      </p>

      <textarea v-model="inputPrimers" />

      <div class="upload">
        <div class="upload-btn" @click="selectFileUpload">选择文件</div>
        <div class="upload-exp">请选择文件上传(只能上传txt, word文件)</div>
      </div>

      <div class="submit-btn" @click="onSubmit">开始引物设计</div>
    </div>

    <template v-if="resultList.length > 0">
      <div
        v-loading="loading"
        element-loading-text="引物分析中..."
        class="result"
      >
        <div class="result-header">
          <h2>引物设计结果</h2>
          <div class="download" @click="exportExecl">下载结果文件</div>
        </div>
        <ul class="result-exp">
          <li>
            -F1,-F2为SNP
            5'-3'序列2条正向引物,-R(60)为5'-3'序列通用反向引物,其中60是去除接头后产物长度。
          </li>
          <li>
            -F1(R),-F2(R),-R(R)(60)为反向 3'-5'序列设计的一套引物，作为备用。
          </li>
        </ul>
        <el-divider />
        <div class="list">
          <template
            v-for="(item, index) of resultList"
            :key="`${item.primer_number}_${index}`"
          >
            <div class="items">
              <div class="item">
                <div class="title">{{ item.name }}(NO.{{ index + 1 }})</div>
                <div class="value">{{ item.primer_number }}</div>
              </div>

              <table>
                <tr>
                  <th>引物ID</th>
                  <th>引物序列</th>
                </tr>
                <tr>
                  <td>-F1</td>
                  <td>{{ item.f1 }}</td>
                </tr>
                <tr>
                  <td>-F2</td>
                  <td>{{ item.f2 }}</td>
                </tr>
                <tr>
                  <td>-R({{ item.length }})</td>
                  <td>{{ item.r }}</td>
                </tr>
              </table>

              <table>
                <tr>
                  <th>引物ID</th>
                  <th>引物序列</th>
                </tr>
                <tr>
                  <td>-F1(R)</td>
                  <td>{{ item.f1_r }}</td>
                </tr>
                <tr>
                  <td>-F2(R)</td>
                  <td>{{ item.f2_r }}</td>
                </tr>
                <tr>
                  <td>-R(R)({{ item.r_length }})</td>
                  <td>{{ item.rr }}</td>
                </tr>
              </table>
            </div>
            <el-divider border-style="dashed" />
          </template>
        </div>
      </div>
    </template>
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import { ElDivider, ElMessage } from "element-plus";
import * as XLSX from "xlsx";

import http from "@/http";
import { selectFile } from "@/utils";
import WapperComponent from "@/components/WapperComponent.vue";
import Bg from "@/assets/images/bluebg.jpg";

interface SNPPrimerDTO {
  id: number;
  name: string;
  primer_number: string;
  f1: string;
  f2: string;
  f1_r: string;
  f2_r: string;
  r: string;
  rr: string;
  length: number;
  r_length: number;
}

const loading = ref(false);
const inputPrimers = ref("");
const resultList = ref<SNPPrimerDTO[]>([]);

function onEffect() {
  window.open("/effect.txt", "_blank");
}

function selectFileUpload() {
  selectFile()
    .then((res) => {
      inputPrimers.value = res as string;
    })
    .catch((err: { message: string }) => {
      ElMessage.error(err.message);
    });
}

function handleSubmit() {
  http
    .post<{ result: SNPPrimerDTO[] }>("/api/snp/analyze_primers", {
      sequence: inputPrimers.value,
    })
    .then((res) => {
      inputPrimers.value = "";
      if (res.code === 200) {
        resultList.value = res.data.result;
      }
    })
    .finally(() => {
      loading.value = false;
    });
}

function onSubmit() {
  if (!inputPrimers.value) {
    ElMessage.error("请您输入序列信息");
    return;
  }
  if (loading.value) {
    ElMessage.warning("序列正在分析中...");
    return;
  }
  loading.value = true;
  handleSubmit();
}

function exportExecl() {
  if (resultList.value.length === 0) {
    ElMessage.error("请先进行引物分析");
    return;
  }

  const execlData = [
    [
      "名称",
      "输入序列",
      "正向野生型(F1)",
      "正向突变型(F2)",
      "正向通用引物(R)",
      "正向通用引物长度",
      "反向野生型(F1)",
      "反向突变型(F2)",
      "反向通用引物(R)",
      "反向通用引物长度",
    ],
  ];
  resultList.value.forEach((item) => {
    const temp = [
      item.name,
      item.primer_number,
      item.f1,
      item.f2,
      item.r,
      String(item.length),
      item.f1_r,
      item.f2_r,
      item.rr,
      String(item.r_length),
    ];
    execlData.push(temp);
  });
  const workbook = XLSX.utils.book_new();
  const sheet = XLSX.utils.aoa_to_sheet(execlData);
  XLSX.utils.book_append_sheet(workbook, sheet, "Sheet1");
  XLSX.writeFile(workbook, "引物设计结果.xlsx");
}
</script>

<style lang="less" scoped>
.snp-primer {
  .highlight-flag {
    background-color: yellow;
  }
  .container {
    width: @app-width;
    margin: 0 auto;
    h2 {
      text-align: center;
      font-size: 45px;
      padding-top: 80px;
      font-weight: 500;
    }
    .exp {
      font-size: 24px;
    }
    .prompt {
      margin: 10px 0;
      &.highlight {
        color: red;
      }

      .prompt-li {
        display: block;
        font-size: 14px;
        margin-top: 10px;
        .prompt-c {
          margin-top: 5px;
          display: block;
          word-break: break-all;
        }
      }
    }
    h3 {
      margin-top: 70px;
      font-size: 32px;
      font-weight: 500;
    }
    .effect {
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
    textarea {
      width: 100%;
      min-height: 200px;
      outline: none;
      resize: vertical;
      border-color: #dcdfe6;
      margin: 10px 0;
      border-radius: 3px;
    }
    .upload {
      display: flex;
      align-items: center;
      margin-bottom: 30px;
      gap: 10px;
      .upload-exp {
        color: #888;
      }
    }
    .submit-btn {
      margin-bottom: 60px;
    }
  }

  .result {
    width: @app-width;
    margin: 0 auto;
    margin-bottom: 60px;
    min-height: 200px;
    .result-header {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      .download {
        cursor: pointer;
        color: #3071a9;
      }
    }
    .result-exp {
      margin-top: 5px;
      padding: 0 20px 0;
      li {
        list-style: disc;
      }
    }
    .list {
      .items {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;
        font-size: 15px;
        .item {
          background-color: #f8f7f6;
          .title {
            padding-bottom: 5px;
            background-color: white;
          }
          .value {
            padding: 10px;
            word-break: break-all;
            border: 1px solid #ddd;
          }
          &:nth-child(1) {
            grid-column: span 2;
          }
          &:nth-child(1) {
            .title {
              font-weight: bold;
            }
          }
        }
        table {
          border-collapse: collapse;
          tr {
            td,
            th {
              border: 1px solid #ddd;
              padding: 6px;
            }
            th {
              background: #f8f7f6;
            }
          }
        }
      }
    }
  }
}
</style>
