import axios, { AxiosError, AxiosResponse } from "axios";

import router from "@/router";
import { SESSION_AUTHORIZATION_KEY } from "@/utils/global";
import { logout } from "@/service/LoginService";

interface ApiResponse<T> {
  code: number;
  message: string;
  data: T;
}

const http = axios.create();
http.interceptors.request.use(
  (config) => {
    const authorization = sessionStorage.getItem(SESSION_AUTHORIZATION_KEY);
    if (authorization) {
      config.headers.authorization = `Bearer ${authorization}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

function handleSuccess<T>(
  response: AxiosResponse<ApiResponse<T>>
): ApiResponse<T> {
  return response.data;
}

function handleError(error: AxiosError): Promise<never> {
  if (error.response) {
    if (error.response.status === 401) {
      logout();
      router.replace("/AccountLogin");
    }

    return Promise.reject(error.response.data);
  } else if (error.request) {
    return Promise.reject("No response from server");
  }
  return Promise.reject(error.message);
}

function get<T>(url: string) {
  return http.get<ApiResponse<T>>(url).then(handleSuccess).catch(handleError);
}

// eslint-disable-next-line
function post<T, D = any>(url: string, data: D, headers: any = {}) {
  return http
    .post<ApiResponse<T>>(url, data, {
      headers,
    })
    .then(handleSuccess)
    .catch(handleError);
}

export default {
  get,
  post,
};
