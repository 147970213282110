import http from "@/http";

export interface KMeansResponse {
  lists: {
    [key: string]: number[][];
  };
  centers: number[][]; // 簇中心点
  center: number[]; // 数据中心点
}

interface KMeansCenter {
  xCenter: number;
  yCenter: number;
}

// 聚类算法
export default class ClusteringService {
  // k-means算法
  static kMeans(list: number[][]): Promise<KMeansResponse> {
    return new Promise((resolve, reject) => {
      const url = "/api/clustering/k_means";

      http.post<KMeansResponse>(url, list).then((res) => {
        if (res.code === 200) {
          resolve(res.data);
        } else {
          reject(null);
        }
      });
    });
  }

  // k-means添加标签
  static kMeansAddLabel(kMeansResult: KMeansResponse, keys: string[]) {
    const { lists, center, centers } = kMeansResult;
    const result: KMeansResponse = {
      lists: {},
      center,
      centers,
    };

    const [xTAvg, yTAvg] = center;
    for (const [index, value] of centers.entries()) {
      const [xAvg, yAvg] = value;
      let key = keys[0];

      if (xAvg < xTAvg && yAvg > yTAvg) {
        key = keys[0];
      } else if (xAvg >= xTAvg && yAvg >= yTAvg) {
        key = keys[1];
      } else if (xAvg > xTAvg && yAvg < yTAvg) {
        key = keys[2];
      } else {
        key = keys[3];
      }

      // 判断结果key值是否已经存在
      const listKeys = Object.keys(result.lists);
      if (listKeys.includes(key)) {
        const list = result.lists[key];
        const { xCenter, yCenter } = ClusteringService.__getKMeansCenter(list);
        const temp = list;

        if (key === keys[2] || key === keys[3]) {
          // 判断x值大小
          if (xAvg > xCenter) {
            result.lists[keys[2]] = lists[`_${index}`];
            result.lists[keys[3]] = temp;
          } else {
            result.lists[keys[3]] = lists[`_${index}`];
            result.lists[keys[2]] = temp;
          }
        } else {
          // 判断y值大小
          if (yAvg > yCenter) {
            result.lists[keys[0]] = lists[`_${index}`];
            result.lists[keys[1]] = temp;
          } else {
            result.lists[keys[1]] = lists[`_${index}`];
            result.lists[keys[0]] = temp;
          }
        }
      } else {
        result.lists[key] = lists[`_${index}`];
      }
    }

    return result;
  }

  // 获取kMeans中心点
  private static __getKMeansCenter(list: number[][]): KMeansCenter {
    let xTotal = 0;
    let yTotal = 0;

    for (const item of list.values()) {
      const [x, y] = item;
      xTotal += x;
      yTotal += y;
    }

    return {
      xCenter: xTotal / list.length,
      yCenter: yTotal / list.length,
    };
  }
}
