<template>
  <div class="snp-decoder">
    <wapper-component title="Welcome to SNP Decoder" :background="Bg" />

    <div class="container">
      <h2>SNP Decoder2.0</h2>
      <p class="exp">
        FLU-ARMS SNP 检测结果的散点图及基因型处理工具。
        可适配第三方酶标仪及Douglas系统荧光输出文件。
      </p>
      <el-divider />

      <p>
        文件示例：
        <b class="effect" @click="onEffect('/96孔酶标仪案例.xlsx')">
          96孔酶标仪案例
        </b>
        <b class="effect" @click="onEffect('/384孔酶标仪案例.xlsx')">
          384孔酶标仪案例
        </b>
        <b class="effect" @click="onEffect('/96孔荧光定量案例.xlsx')">
          96孔荧光定量案例
        </b>
        <b class="effect" @click="onEffect('/384孔荧光定量案例.xlsx')">
          384孔荧光定量案例
        </b>
      </p>

      <div class="upload">
        <div class="upload-btn" @click="handleSelectFile">选择文件</div>
        <div class="upload-exp">
          {{
            selectedFile
              ? selectedFile.name
              : "请选择文件上传(只能上传xlsx的模版文件)"
          }}
        </div>
      </div>

      <div class="submit-btn" @click="handleSubmit">开始设计</div>

      <div class="result-container">
        <div
          v-if="!showReuslt"
          v-html="getXssFilter(htmlContent)"
          class="result-html"
        ></div>

        <div v-if="showReuslt" class="tools">
          <div class="classes legend">
            <div v-for="item in classesDatas" :key="item">
              <div
                class="name"
                :class="{ active: item === selectedClasses }"
                @click="selectedClasses = item"
              >
                {{ item === "undefined" ? "空标签" : item }}
              </div>
            </div>
          </div>

          <el-button :loading="downloadLoading" link @click="onDownloadData">
            下载数据
          </el-button>
        </div>
        <div class="echart-box">
          <div ref="refScatterPlot" class="echart-div echart-plot"></div>
          <div ref="refTableChart" class="echart-div table-chart"></div>
        </div>

        <div class="legend label-legend">
          <div v-for="(item, index) in legends" :key="item">
            <div
              class="icons"
              :class="{
                'f-icons': index === legends.length - 3,
              }"
              :style="{ backgroundColor: item.styles.itemStyle.color }"
            ></div>
            <div class="name">{{ item.key }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, ref, watch } from "vue";
import { ElDivider, ElButton } from "element-plus";

import WapperComponent from "@/components/WapperComponent.vue";
import Bg from "@/assets/images/decoderbg.jpg";
import {
  useConfig,
  FLUORESCENCE_POINT_STYLES,
  ENZYME_POINT_STYLES,
} from "./config";
import http from "@/http";
import DOMPurify from "dompurify";

interface ResultInterface {
  id: number;
  name: string;
  value: string;
  type: string;
}

const {
  refScatterPlot,
  refTableChart,
  selectedFile,
  dataAnalysisType,
  classesDatas,
  selectedClasses,
  downloadLoading,
  showReuslt,

  handleSelectFile,
  handleSubmit,
  onDownloadData,
} = useConfig();

// eslint-disable-next-line
const legends = ref<any>();
const htmlContent = ref<string>("");

onMounted(() => {
  getHtml();
});

watch(dataAnalysisType, (val) => {
  if (val === "ENZYME") {
    legends.value = ENZYME_POINT_STYLES;
  } else {
    legends.value = FLUORESCENCE_POINT_STYLES;
  }
});

// 下载案例
function onEffect(path: string) {
  window.open(path, "_blank");
}

// 空白处HTML
function getHtml() {
  http.get<ResultInterface>("/api/snp/get_decoder_html").then((res) => {
    if (res.code === 200 && res.data) {
      htmlContent.value = res.data.value;
    }
  });
}

// 内容
function getXssFilter(content: string) {
  return DOMPurify.sanitize(content);
}
</script>

<style lang="less" scoped>
.snp-decoder {
  .container {
    width: @app-width;
    margin: 0 auto;
    h2 {
      text-align: center;
      font-size: 45px;
      padding-top: 80px;
      font-weight: 500;
    }
    .exp {
      font-size: 24px;
    }
    .prompt {
      margin: 10px 0;
    }
    h3 {
      margin-top: 70px;
      font-size: 32px;
      font-weight: 500;
    }
    .effect {
      cursor: pointer;
      margin-right: 10px;
      &:hover {
        text-decoration: underline;
      }
    }
    textarea {
      width: 100%;
      min-height: 200px;
      outline: none;
      resize: vertical;
      border-color: #dcdfe6;
      margin: 10px 0;
      border-radius: 3px;
    }
    .upload {
      display: flex;
      align-items: center;
      margin-bottom: 30px;
      gap: 10px;
      .upload-exp {
        color: #888;
      }
    }
    .submit-btn {
      margin-bottom: 30px;
    }
  }

  .result-container {
    @c-height: 600px;
    width: @app-width;
    margin: 0 auto;
    min-height: @c-height;
    margin-bottom: 40px;
    position: relative;
    .result-html {
      width: @app-width;
      margin: 0 auto;
      height: @c-height;
      z-index: 1;
      position: absolute;
    }
    .tools {
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .legend {
      display: flex;
      align-items: center;
      gap: 15px;
      font-size: 14px;
      & > div {
        display: flex;
        align-items: center;
        gap: 5px;
        .icons {
          @size: 12px;
          width: @size;
          height: @size;
          background-color: red;
          border-radius: 50%;
        }
        .f-icons {
          background-color: black;
          border-radius: 0;
        }
      }
    }
    .label-legend {
      margin: 20px 0;
      justify-content: center;
    }
    .classes {
      & > div {
        cursor: pointer;
      }
      .name {
        &.active {
          color: @bcolor;
        }
      }
    }
    .echart-box {
      display: flex;
      height: @c-height;
    }
    .echart-div {
      height: @c-height;
      flex: 1;
    }
  }
}
</style>
