import { ElLoading } from "element-plus";
import mammoth from "mammoth";

interface XlsxPostion {
  x: string;
  y: string;
}

export function onBlast() {
  const url =
    "https://blast.ncbi.nlm.nih.gov/Blast.cgi?PROGRAM=blastn&PAGE_TYPE=BlastSearch&LINK_LOC=blasthome";
  window.open(url, "_target");
}

export function gLoading(content = "加载中...") {
  return ElLoading.service({
    text: content,
    background: "rgba(0, 0, 0, 0.7)",
  });
}

export function getUrlParam(key: string): string | null {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get(key);
}

export function selectFile(accept = ".txt,.docx") {
  return new Promise((resolve, reject) => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = accept;
    fileInput.style.display = "none";

    fileInput.addEventListener("change", (event) => {
      if (!event || !event.target) {
        reject({ message: "请选择文件" });
      }
      const selectdFile = (event.target as HTMLInputElement).files?.[0];
      const isDocx = selectdFile?.name.endsWith(".docx");
      if (selectdFile) {
        const render = new FileReader();
        render.onload = (e) => {
          const content = e.target?.result as string | ArrayBuffer;

          if (isDocx) {
            mammoth
              .extractRawText({ arrayBuffer: content as ArrayBuffer })
              .then((res) => {
                resolve(res.value);
              });
          } else {
            resolve(content);
          }
        };

        if (isDocx) {
          render.readAsArrayBuffer(selectdFile);
        } else {
          render.readAsText(selectdFile);
        }
      } else {
        reject({ message: "请选择有效的文件" });
      }

      document.body.removeChild(fileInput);
    });

    document.body.appendChild(fileInput);
    fileInput.click();
  });
}

// 打开并选择文件
export function openSelectFile(accept = ".xlsx"): Promise<File> {
  return new Promise((resolve, reject) => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = accept;
    fileInput.style.display = "none";

    fileInput.addEventListener("change", (event) => {
      if (!event || !event.target) {
        reject({ message: "请选择文件" });
      }
      const selectdFile = (event.target as HTMLInputElement).files?.[0];
      if (selectdFile) {
        resolve(selectdFile);
      } else {
        reject({ message: "请选择有效的文件" });
      }

      document.body.removeChild(fileInput);
    });

    document.body.appendChild(fileInput);
    fileInput.click();
  });
}

// 判断数组是否相等
export function arrayAreEqual(
  arr1: (string | number)[],
  arr2: (string | number)[]
) {
  return (
    arr1.length === arr2.length &&
    arr1.every((value, index) => value === arr2[index])
  );
}

// 解析xlsx坐标
export function parseXlsxPostion(value: string): XlsxPostion {
  const matches = value.match(/([A-Za-z]+)(\d+)/);
  if (matches && matches.length === 3) {
    return {
      x: matches[2],
      y: matches[1],
    };
  }
  return {
    x: "",
    y: "",
  };
}

// 浮点型判断
export function isFloatEqual(num1: number, num2: number, epsilon = 1e-10) {
  return Math.abs(num1 - num2) < epsilon;
}

/* eslint-disable */
// 防抖
export function debounce(
  func: Function,
  wait: number
) {
  let timeout: number | null;

  return function (this: any, ...args: any[]) {
    const context = this;

    clearTimeout(timeout!);
    timeout = setTimeout(() => {
      timeout = null;
      func.apply(context, args);
    }, wait);
  };
}

// 使用递归的方式实现深拷贝
export function deepCopy(obj: any): any {
  if (obj === null || typeof obj !== 'object') {
    return obj;
  }

  if (Array.isArray(obj)) {
    // 处理数组的深拷贝
    return obj.map((item) => deepCopy(item));
  }

  // 处理对象的深拷贝
  const copy: { [key: string]: any } = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      copy[key] = deepCopy(obj[key]);
    }
  }

  return copy;
}
/* eslint-disable */
