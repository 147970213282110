import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

import { SESSION_AUTHORIZATION_KEY } from "@/utils/global";

import HomeView from "@/views/HomeView.vue";
import SNPPrimerView from "@/views/SNPPrimer/IndexView.vue";
import SNPPrimerEffectView from "@/views/SNPPrimer/EffectView.vue";
import SNPDecoderView from "@/views/SNPDecoder/IndexView.vue";
import ContactUSView from "@/views/ContactUS/IndexView.vue";
import AccountLoginView from "@/views/Account/LoginView.vue";
import AccountRegisterView from "@/views/Account/RegisterView.vue";
import AccountForgetPasswordView from "@/views/Account/ForgetPasswordView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/SNPPrimer",
    name: "SNPPrimer",
    component: SNPPrimerView,
    meta: {
      isAuth: true,
    },
  },
  {
    path: "/SNPPrimerEffect",
    name: "SNPPrimerEffect",
    component: SNPPrimerEffectView,
    meta: {
      isAuth: true,
    },
  },
  {
    path: "/SNPDecoder",
    name: "SNPDecoderView",
    component: SNPDecoderView,
    meta: {
      isAuth: true,
    },
  },
  {
    path: "/ContactUS",
    name: "ContactUS",
    component: ContactUSView,
  },
  {
    path: "/AccountLogin",
    name: "AccountLogin",
    component: AccountLoginView,
  },
  {
    path: "/AccountRegister",
    name: "AccountRegister",
    component: AccountRegisterView,
  },
  {
    path: "/AccountForgetPassword",
    name: "AccountForgetPassword",
    component: AccountForgetPasswordView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to) => {
  const hasAuthorication = sessionStorage.getItem(SESSION_AUTHORIZATION_KEY);
  if (to.meta && to.meta.isAuth && !hasAuthorication) {
    return {
      path: "/AccountLogin",
      query: {
        redirect: to.fullPath,
      },
    };
  }
});

export default router;
