// +----------------------------------------------------------------------
// - 96/384孔酶标仪案例分析(enzyme)
// - 96/384孔荧光定量案例分析(fluorescence)
// - 调试的时候最好在选择文件之后把console数据打开，不然很难修改
// +----------------------------------------------------------------------
/* eslint-disable @typescript-eslint/no-explicit-any */

import { onMounted, ref, watch } from "vue";
import * as XLSX from "xlsx";
import * as echarts from "echarts";
import { ElMessage } from "element-plus";

import {
  openSelectFile,
  parseXlsxPostion,
  debounce,
  isFloatEqual,
  deepCopy,
} from "@/utils";
import ClusteringService, { KMeansResponse } from "@/service/ClusteringService";
import SNPDecoderService from "@/service/SNPDecoderService";

import _96_X from "./data/_96_X.json";
import _96_Y from "./data/_96_Y.json";
import _384_X from "./data/_384_X.json";
import _384_Y from "./data/_384_Y.json";

interface KeyValue {
  [key: string]: any;
}

interface EnzymeDataType {
  name: string;
  x: string;
  y: string;
  radio: string;
}

/**
 * 分析的数据类型
 */
type AnalysisType = "" | "ENZYME" | "FLUORESCENCE";

const MENU_ID = "ECHART_CONTEXT_MENU";
const TEMPLATE_ERROR_TIP = "请选择正确的文件模版";
const WELL_POSTION_96 = 96; // 96孔
const WELL_POSITION_384 = 384; // 384孔

// echart配置项
const COLORS = ["red", "blue", "green", "black", "black", "gray"];
const ECHART_DATA_ZOOM_SIZE = "18px";
const ECHART_MIN_SPAN = 30;
const ECHART_DATA_ZOOM: echarts.DataZoomComponentOption[] = [
  {
    type: "inside",
    minSpan: ECHART_MIN_SPAN,
  },
  {
    type: "inside",
    orient: "vertical",
    minSpan: ECHART_MIN_SPAN,
  },
  {
    type: "inside",
    height: ECHART_DATA_ZOOM_SIZE,
    minSpan: ECHART_MIN_SPAN,
  },
  {
    type: "inside",
    width: ECHART_DATA_ZOOM_SIZE,
    left: "0",
    orient: "vertical",
    minSpan: ECHART_MIN_SPAN,
  },
];
const ECHART_BRUSH: echarts.BrushComponentOption = {
  toolbox: ["polygon"],
};

/**
 * 孔荧光点样式
 */
export const FLUORESCENCE_POINT_STYLES = [
  {
    key: "Homozygous Allele 1/Allele 1",
    styles: {
      symbol: "circle",
      itemStyle: {
        color: COLORS[0],
      },
    },
  },
  {
    key: "Homozygous Allele 2/Allele 2",
    styles: {
      symbol: "circle",
      itemStyle: {
        color: COLORS[1],
      },
    },
  },
  {
    key: "Heterozygous Allele 1/Allele 2",
    styles: {
      symbol: "circle",
      itemStyle: {
        color: COLORS[2],
      },
    },
  },
  {
    key: "Negative Control (NC)",
    styles: {
      symbol: "rect",
      itemStyle: {
        color: COLORS[3],
      },
    },
  },
  {
    key: "Undetermined",
    styles: {
      symbol: "circle",
      itemStyle: {
        color: COLORS[4],
      },
    },
  },
  {
    key: "Omit",
    styles: {
      symbol: "circle",
      itemStyle: {
        color: COLORS[5],
      },
    },
  },
] as const;

/**
 * 孔酶标仪样式
 */
export const ENZYME_POINT_STYLES = [...FLUORESCENCE_POINT_STYLES] as const;

/**
 * 选中的样式
 */
const SELECTED_COLOR = "rgba(255, 255, 0, 1)";
const SELECTED_SIZE = 3;

// 配置hook
export function useConfig() {
  const selectedFile = ref<File>(); // 选择的文件
  const refScatterPlot = ref(null);
  const refTableChart = ref(null);
  let __dataAnalysisType: AnalysisType;
  const dataAnalysisType = ref<AnalysisType>(""); // 数据分析类型
  const classesDatas = ref<string[]>([]); // 分类数据
  const selectedClasses = ref(""); // 选中的分类
  const downloadLoading = ref(false);
  const showReuslt = ref(false);

  const __contextMenu = ref<HTMLDivElement>();
  let __echartScatterPlot: echarts.ECharts;
  let __echartTableChart: echarts.ECharts;

  let __xAxis: string[] = [];
  let __yAxis: string[] = [];

  let __selectedFileFluorescenceData: KeyValue[] = []; // 选择的孔荧光数据
  let __selectedFileEnzymeData: KeyValue[] = []; // 选择的孔酶标数据
  let __selectedFileEnzymeComment: KeyValue[] = []; // 选择的孔酶标注释
  let __result: KeyValue; // key-value数据(解析后的最终数据)
  let __kMeansLabels: KeyValue = {};

  // 侦听类型改变
  watch(classesDatas, (item) => {
    if (item.length > 0) {
      // 强行重新触发孔酶类型改变
      selectedClasses.value = "";
      setTimeout(() => {
        selectedClasses.value = item[0];
      }, 0);
    }
  });

  // 侦听类型选中变化
  watch(selectedClasses, (key) => {
    if (!key) {
      return;
    }
    __renderEchart(key);
  });

  onMounted(() => {
    // 移除菜单
    document.addEventListener("click", () => {
      __removeContextMenu();
    });
  });

  // 下载数据
  async function onDownloadData() {
    downloadLoading.value = true;

    const snpDecoderService = new SNPDecoderService(
      selectedFile.value?.name ?? "",
      __dataAnalysisType === "ENZYME" ? "酶标仪" : "荧光定量"
    );

    // 先设置好数据
    for (const key of Object.keys(__result)) {
      await __getLabelResult(key);
    }

    snpDecoderService.downloadData(__result, __kMeansLabels);
    downloadLoading.value = false;
  }

  // 选择xlsx文件
  function handleSelectFile() {
    openSelectFile().then((file) => {
      selectedFile.value = file;

      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target?.result;
        const workbook = XLSX.read(data, { type: "binary" });
        const { SheetNames } = workbook;

        if (SheetNames.length === 1) {
          __dataAnalysisType = "FLUORESCENCE";
        } else if (SheetNames.length === 2) {
          __dataAnalysisType = "ENZYME";
        } else {
          ElMessage.error(TEMPLATE_ERROR_TIP);
          return;
        }

        if (__dataAnalysisType === "FLUORESCENCE") {
          const sheetName = SheetNames[0];
          const sheet = workbook.Sheets[sheetName];

          __selectedFileFluorescenceData = XLSX.utils.sheet_to_json(sheet);
        } else {
          const [sheetData, sheetComment] = SheetNames;

          __selectedFileEnzymeData = XLSX.utils.sheet_to_json(
            workbook.Sheets[sheetData]
          );
          __selectedFileEnzymeComment = XLSX.utils.sheet_to_json(
            workbook.Sheets[sheetComment]
          );
        }
      };
      reader.readAsBinaryString(file);
    });
  }

  // 提交设计
  function handleSubmit() {
    __resetData();

    if (!__echartScatterPlot) {
      __echartScatterPlot = echarts.init(refScatterPlot.value);
    }
    if (!__echartTableChart) {
      __echartTableChart = echarts.init(refTableChart.value);
    }

    dataAnalysisType.value = __dataAnalysisType;
    __initEvent();
    __parseXlsx();
  }

  // 重置所有数据
  function __resetData() {
    __kMeansLabels = {};
    if (__echartScatterPlot) {
      __echartScatterPlot.off("click");
      __echartScatterPlot.off("contextmenu");
      __echartScatterPlot.off("brushSelected");
    }
    if (__echartTableChart) {
      __echartTableChart.off("click");
      __echartTableChart.off("contextmenu");
      __echartTableChart.off("brushSelected");
    }
  }

  // 初始化事件
  function __initEvent() {
    // 散点图左击事件
    __echartScatterPlot.on("click", "series", __handleLeftClickStyle);

    // 散点图右击事件
    __echartScatterPlot.getZr().on("contextmenu", __handleRightClickMenu);

    // 散点图圈选事件
    __echartScatterPlot.on(
      "brushSelected",
      debounce(__handleScatterPlotBrushSelected, 300)
    );

    // 图表矩阵左击事件
    __echartTableChart.on("click", "series", __handleLeftClickStyle);

    // 图表矩阵右击事件
    __echartTableChart.getZr().on("contextmenu", __handleRightClickMenu);

    // 图表矩阵圈选事件
    __echartTableChart.on(
      "brushSelected",
      debounce(__handleTableChartBrushSelected, 300)
    );
  }

  // 解析xlsx
  function __parseXlsx() {
    if (__dataAnalysisType === "FLUORESCENCE") {
      __parseFluorescenceData();
      return;
    }
    __parseEnzymeData();
  }

  // 解析孔荧光定量数据
  function __parseFluorescenceData() {
    const fileHeaders = Object.keys(__selectedFileFluorescenceData[0]);
    if (fileHeaders[0] !== "Position") {
      ElMessage.error(TEMPLATE_ERROR_TIP);
      return;
    }

    // 判断孔位
    if (__selectedFileFluorescenceData.length === WELL_POSTION_96) {
      __xAxis = _96_X;
      __yAxis = _96_Y;
    } else if (__selectedFileFluorescenceData.length === WELL_POSITION_384) {
      __xAxis = _384_X;
      __yAxis = _384_Y;
    } else {
      ElMessage.error(TEMPLATE_ERROR_TIP);
      return;
    }

    __setFluorescenceResult();
  }

  // 设置荧光定量结果数据
  function __setFluorescenceResult() {
    const fluorecenceResult: KeyValue = [];

    __selectedFileFluorescenceData.forEach((item) => {
      const { Markers, FAM, ROX, Position } = item;
      const HEXVIC = item["HEX/VIC"];
      const commenPosition = parseXlsxPostion(Position);

      if (!fluorecenceResult[Markers]) {
        fluorecenceResult[Markers] = [];
      }
      fluorecenceResult[Markers].push({
        x: FAM ?? null,
        y: HEXVIC ?? null,
        radio: ROX ?? null,
        name: `${commenPosition.y}${Number(commenPosition.x)}` ?? null,
        ...item,
      });
    });

    __result = fluorecenceResult;
    classesDatas.value = Object.keys(fluorecenceResult);
  }

  // 解析孔酶标仪数据
  function __parseEnzymeData() {
    const wellPositionIndex = 22; // 判断孔位的下标/key
    const dataInterval = 15; // 数据列间隔
    const wellPositionValues = Object.values(
      __selectedFileEnzymeData[wellPositionIndex]
    );
    const _96_wellPositionXLength = 12;
    const _384_wellPositionXLength = 24;

    if (wellPositionValues.length - 1 === _96_wellPositionXLength) {
      __xAxis = _96_X;
      __yAxis = _96_Y;
    } else if (wellPositionValues.length - 1 === _384_wellPositionXLength) {
      __xAxis = _384_X;
      __yAxis = _384_Y;
    } else {
      ElMessage.error(TEMPLATE_ERROR_TIP);
      return;
    }

    const enzymeDatas: EnzymeDataType[] = [];

    let startIndex = wellPositionIndex + 1;
    let endIndex = startIndex + __yAxis.length;
    __setEnzymeData(startIndex, endIndex, "x", enzymeDatas); // 处理x值

    startIndex = endIndex + dataInterval;
    endIndex = startIndex + __yAxis.length;
    __setEnzymeData(startIndex, endIndex, "y", enzymeDatas); // 处理y值

    startIndex = endIndex + dataInterval;
    endIndex = startIndex + __yAxis.length;
    __setEnzymeData(startIndex, endIndex, "radio", enzymeDatas); // 处理radio值

    __setEnzymeCommontData(enzymeDatas);
  }

  // 设置孔酶标仪数据
  function __setEnzymeData(
    startIndex: number,
    endIndex: number,
    handleKey: "x" | "y" | "radio",
    enzymeDatas: EnzymeDataType[]
  ) {
    let index = 0;
    for (let i = startIndex; i < endIndex; i++) {
      // 判断是否越界
      if (!__selectedFileEnzymeData[i]) {
        return;
      }

      const yKey = __selectedFileEnzymeData[i]["xxx"];

      for (const [key, value] of Object.entries(__selectedFileEnzymeData[i])) {
        if (key === "xxx") {
          continue;
        }

        const xKeys = key.split("__EMPTY_");
        enzymeDatas[index] = {
          name: `${yKey}${
            xKeys.length === 1 ? "1" : String(Number(xKeys[1]) + 1)
          }`,
          x: enzymeDatas[index] ? enzymeDatas[index].x : "",
          y: enzymeDatas[index] ? enzymeDatas[index].y : "",
          radio: enzymeDatas[index] ? enzymeDatas[index].radio : "",
        };
        enzymeDatas[index][handleKey] = String(value);
        index++;
      }
    }
  }

  // 设置孔酶标注释
  function __setEnzymeCommontData(enzymeDatas: EnzymeDataType[]) {
    const enzymeCommontDatas: KeyValue = {};
    __selectedFileEnzymeComment.forEach((item) => {
      const { Markers, Position, Sample_name } = item;
      const commenPosition = parseXlsxPostion(Position);

      const findEnzymeData = enzymeDatas.find(
        (item) => item.name === `${commenPosition.y}${Number(commenPosition.x)}`
      );

      if (findEnzymeData) {
        if (!enzymeCommontDatas[Markers]) {
          enzymeCommontDatas[Markers] = [];
        }

        enzymeCommontDatas[Markers].push({
          Markers,
          Sample_name,
          ...findEnzymeData,
        });
      }
    });

    __result = enzymeCommontDatas;
    classesDatas.value = Object.keys(enzymeCommontDatas);
  }

  // 渲染图表
  async function __renderEchart(key: string) {
    const dataScatterPlot: any[] = [];
    const dataTableChart: any[] = [];
    const labelResult = await __getLabelResult(key);

    __result[key].forEach((item: any) => {
      const { xValue, yValue } = __getXYValues(item);
      const value = [xValue, yValue];
      dataScatterPlot.push(__getData(item, value, labelResult, true));
    });

    // 图表矩阵数据
    __xAxis.forEach((xItem) => {
      __yAxis.forEach((yItem) => {
        if (xItem === " ") {
          return;
        }

        const rFind = __result[key].find(
          (rItem: any) => rItem.name === `${yItem}${xItem}`
        );
        const value = [xItem, yItem];
        if (rFind) {
          dataTableChart.push(__getData(rFind, value, labelResult));
        } else {
          dataTableChart.push({
            name: `${yItem}${xItem}`,
            value,
            itemStyle: {
              color: "darkgray",
            },
            emphasis: {
              scale: false,
            },
          });
        }
      });
    });

    __echartScatterPlot.setOption(__optionScatterPlot(dataScatterPlot));
    __echartTableChart.setOption(__optionTableChart(dataTableChart));

    showReuslt.value = true;
  }

  // 获取xy值
  function __getXYValues(data: any) {
    let xValue = Number(data.x);
    let yValue = Number(data.y);
    const radio = Number(data.radio);

    if (!isNaN(radio) && radio !== 0) {
      xValue = xValue / radio;
      yValue = yValue / radio;
    }

    return {
      xValue,
      yValue,
    };
  }

  // 标签配置
  function __getEchartLabel(name: string, isOffset = false) {
    return {
      show: false,
      borderWidth: 1,
      fontWeight: "bolder",
      fontFamily: "Microsoft YaHei",
      formatter: name,
      offset: isOffset ? [10, 20] : [0, 0],
    };
  }

  // 获取孔酶标仪Data
  function __getData(
    find: any,
    value: (string | number)[],
    labelResult: KMeansResponse,
    isOffset = false
  ) {
    const { xValue, yValue } = __getXYValues(find);

    return {
      ...find,
      ...__getStyle(__getKey(labelResult, xValue, yValue)),
      value,
      label: __getEchartLabel(String(find.Sample_name), isOffset),
    };
  }

  // 获取label结果数据
  async function __getLabelResult(key: string) {
    let labelResult: KMeansResponse;

    if (__kMeansLabels[key]) {
      labelResult = __kMeansLabels[key];
    } else {
      const kMeansResult = await __calculateKMeans(key);
      const keys = [
        "Homozygous Allele 1/Allele 1",
        "Heterozygous Allele 1/Allele 2",
        "Homozygous Allele 2/Allele 2",
        "Negative Control (NC)",
      ];

      labelResult = ClusteringService.kMeansAddLabel(kMeansResult, keys);
      __kMeansLabels[key] = labelResult;
    }

    return labelResult;
  }

  // 计算聚类
  async function __calculateKMeans(key: string) {
    const currentEnzymeData = __result[key];
    const xyValue: number[][] = [];

    currentEnzymeData.forEach((item: any) => {
      let x = Number(item.x);
      let y = Number(item.y);
      const radio = Number(item.radio);

      if (!isNaN(radio) && radio !== 0) {
        x = x / radio;
        y = y / radio;
      }

      if (x && y) {
        xyValue.push([x, y]);
      }
    });

    if (xyValue.length < 5) {
      return {
        center: [],
        centers: [],
        lists: {},
      };
    }

    return await ClusteringService.kMeans(xyValue);
  }

  // 获取KEY
  function __getKey(
    labelResult: KMeansResponse,
    x: number,
    y: number
  ):
    | typeof ENZYME_POINT_STYLES[number]["key"]
    | typeof FLUORESCENCE_POINT_STYLES[number]["key"] {
    const { lists } = labelResult;
    const list =
      __dataAnalysisType === "ENZYME"
        ? ENZYME_POINT_STYLES
        : FLUORESCENCE_POINT_STYLES;

    for (const [key, item] of Object.entries(lists)) {
      for (const value of item.values()) {
        const [labelX, labelY] = value;

        if (isFloatEqual(labelX, x) && isFloatEqual(labelY, y)) {
          return key as typeof list[number]["key"];
        }
      }
    }

    return list[list.length - 2].key;
  }

  // 获取点的样式
  function __getStyle(key: string) {
    const list: any =
      __dataAnalysisType === "ENZYME"
        ? ENZYME_POINT_STYLES
        : FLUORESCENCE_POINT_STYLES;

    const find = list.find((item: any) => item.key === key);
    return find?.styles;
  }

  // 自定义工具
  function __toolboxFeatrue(isLeft = false) {
    const icon =
      "path://M405.793812 767.906261V349.082387c0-13.998291-9.248871-23.247162-23.247162-23.247162-13.998291 0-23.247162 9.248871-23.247162 23.247162v418.823874c0 13.998291 9.248871 23.247162 23.247162 23.247163 13.873306 0 23.247162-9.248871 23.247162-23.247163z m395.576712 162.855121c0 25.621872-20.997437 46.494324-46.494324 46.494324H242.938692c-25.621872 0-46.494324-20.997437-46.494324-46.494324V349.082387c0-13.998291-9.248871-23.247162-23.247163-23.247162-13.998291 0-23.247162 9.248871-23.247162 23.247162v581.803979c0 51.243745 41.869889 93.113634 93.113634 93.113634h511.937507c51.243745 0 93.113634-41.869889 93.113634-93.113634V349.082387c0-13.998291-9.248871-23.247162-23.247162-23.247162-13.998291 0-23.247162 9.248871-23.247163 23.247162v581.678995z m139.607958-744.6591H778.123362V93.113634c0-51.243745-41.869889-93.113634-93.113634-93.113634H312.680179c-51.243745 0-93.113634 41.869889-93.113634 93.113634V186.227267H56.711425c-13.998291 0-23.247162 9.373856-23.247162 23.247162 0 13.998291 9.248871 23.247162 23.247162 23.247163h884.267057c13.998291 0 23.247162-9.373856 23.247162-23.247163 0-13.998291-9.248871-23.372147-23.247162-23.372147z m-209.474429 0H266.185854V93.113634c0-25.621872 20.997437-46.494324 46.494325-46.494325h372.329549c25.621872 0 46.494324 20.997437 46.494325 46.494325v92.988648z m-92.988649 581.803979V349.082387c0-13.998291-9.248871-23.247162-23.247162-23.247162-13.998291 0-23.247162 9.248871-23.247163 23.247162v418.823874c0 13.998291 9.248871 23.247162 23.247163 23.247163 13.873306 0 23.247162-9.248871 23.247162-23.247163z m0 0";
    const labelIcon =
      "M1005.568 58.88c0-22.528-17.92-40.448-40.448-40.448l-409.6 0.512-512.512 512.512c-32.256 32.256-32.256 84.48 0 116.736l332.8 332.8c16.384 16.384 37.376 24.064 58.368 24.064s42.496-8.192 58.368-24.064l512.512-512.512-0.512-0.512 1.024-409.088z m-51.712 389.12l-497.152 497.152c-6.144 6.144-13.824 9.216-22.016 9.216s-16.384-3.072-22.016-9.216l-332.8-332.8c-6.144-6.144-9.216-13.824-9.216-22.016 0-8.192 3.072-16.384 9.216-22.016l497.664-497.664 377.344-0.512-1.024 377.856z M775.168 360.96c61.952 0 112.128-50.176 112.128-112.128s-50.176-112.128-112.128-112.128-112.128 50.176-112.128 112.128 50.688 112.128 112.128 112.128z m0-172.544c33.28 0 60.928 27.136 60.928 60.928s-27.136 60.928-60.928 60.928-60.928-27.136-60.928-60.928 27.648-60.928 60.928-60.928z";

    return {
      myShowLabel: {
        show: true,
        icon: labelIcon,
        title: "显示/隐藏标签",
        onclick: () => __showEchartLabel(isLeft),
      },
      myClearBrush: {
        show: true,
        title: "清除选择",
        icon,
        onclick: () => __removeAllSelected(),
      },
      saveAsImage: {},
    };
  }

  // 显示或者隐藏标签
  function __showEchartLabel(isLeft = false) {
    const {
      tableChartOptionValue,
      scatterPlotOptionValue,
      tableChartOptionDatas,
      scatterPlotOptionDatas,
    } = __getEchartsOption();

    let data: any;
    if (isLeft) {
      data = scatterPlotOptionDatas;
    } else {
      data = tableChartOptionDatas;
    }

    for (const item of Object.values(data)) {
      const temp: any = item;
      const status = !temp.label?.show ?? false;

      if (temp.label) {
        temp.label.show = status;
      }
      if (temp.emphasis.label) {
        temp.emphasis.label.show = status;
      }
    }

    __echartTableChart.setOption(tableChartOptionValue);
    __echartScatterPlot.setOption(scatterPlotOptionValue);
  }

  // 散点图配置
  function __optionScatterPlot(data: any): echarts.EChartsOption {
    let xMin = Infinity;
    let yMin = Infinity;
    let xMax = 0;
    let yMax = 0;
    const offsetMin = 0.7;
    const offsetMax = 1.1;

    for (const item of data.values()) {
      const [x, y] = item.value;
      xMin = Math.min(xMin, x);
      yMin = Math.min(yMin, y);
      xMax = Math.max(xMax, x);
      yMax = Math.max(yMax, y);
    }

    return {
      title: {
        text: "Allelic Discrimination Plot",
        left: "left",
      },
      xAxis: {
        name: "Allele1",
        min: Number((xMin * offsetMin).toFixed(2)),
        max: Number((xMax * offsetMax).toFixed(2)),
        splitLine: {
          show: false,
        },
      },
      yAxis: {
        name: "Allele2",
        min: Number((yMin * offsetMin).toFixed(2)),
        max: Number((yMax * offsetMax).toFixed(2)),
        splitLine: {
          show: false,
        },
      },
      dataZoom: ECHART_DATA_ZOOM,
      toolbox: {
        right: 50,
        itemSize: 18,
        feature: __toolboxFeatrue(true),
      },
      brush: ECHART_BRUSH,
      series: [
        {
          data,
          type: "scatter",
          symbolSize: 12,
          emphasis: {
            scale: 1.5,
          },
        },
      ],
    };
  }

  // 图表矩阵配置
  function __optionTableChart(data: any): echarts.EChartsOption {
    const fileName = selectedFile.value?.name.split(".");

    return {
      title: {
        text: `板号：${fileName?.[0] ?? "-"}`,
        left: "left",
      },
      xAxis: {
        type: "category",
        data: __xAxis,
        position: "top",
        axisTick: {
          alignWithLabel: true,
        },
        boundaryGap: false,
        max: __xAxis.length - 1,
      },
      yAxis: {
        type: "category",
        data: __yAxis,
        inverse: true,
      },
      grid: {
        left: 20,
        right: 10,
      },
      brush: ECHART_BRUSH,
      toolbox: {
        itemSize: 18,
        feature: __toolboxFeatrue(),
      },
      series: [
        {
          type: "scatter",
          symbolSize: 18,
          data,
          emphasis: {
            scale: 1.2,
          },
        },
      ],
    };
  }

  // 获取echart配置
  function __getEchartsOption() {
    const tableChartOptionValue: echarts.EChartsCoreOption =
      __echartTableChart.getOption();
    const scatterPlotOptionValue: echarts.EChartsCoreOption =
      __echartScatterPlot.getOption();

    const tableChartOptionDatas = (tableChartOptionValue.series as any)[0].data;
    const scatterPlotOptionDatas = (scatterPlotOptionValue.series as any)[0]
      .data;

    return {
      tableChartOptionValue,
      scatterPlotOptionValue,
      tableChartOptionDatas,
      scatterPlotOptionDatas,
    };
  }

  // 设置选中配置
  function __setSelectedData(findData: any) {
    if (!findData) {
      return;
    }

    if (findData.selected) {
      findData.selected = false;
      findData.itemStyle.borderWidth = 0;
      findData.itemStyle.shadowBlur = 0;
    } else {
      findData.selected = true;

      if (!findData.itemStyle) {
        findData.itemStyle = {};
      }

      findData.itemStyle.borderWidth = SELECTED_SIZE;
      findData.itemStyle.borderColor = SELECTED_COLOR;
      findData.itemStyle.shadowBlur = 5;
      findData.itemStyle.shadowColor = "rgba(147, 137, 83, 0.5)";
    }
  }

  // 处理左击选中/取消选中的样式
  function __handleLeftClickStyle(e: echarts.ECElementEvent) {
    const tableChartOptionValue: echarts.EChartsCoreOption =
      __echartTableChart.getOption();
    const scatterPlotOptionValue: echarts.EChartsCoreOption =
      __echartScatterPlot.getOption();

    const tableChartOptionDatas = (tableChartOptionValue.series as any)[0].data;
    const scatterPlotOptionDatas = (scatterPlotOptionValue.series as any)[0]
      .data;

    const findTableChartData = tableChartOptionDatas.find(
      (item: any) => item.name === (e.data as any).name
    );
    const findScatterPlotData = scatterPlotOptionDatas.find(
      (item: any) => item.name === (e.data as any).name
    );

    if (!findTableChartData || !findScatterPlotData) {
      return;
    }

    __setSelectedData(findTableChartData);
    __setSelectedData(findScatterPlotData);

    __echartTableChart.setOption(tableChartOptionValue);
    __echartScatterPlot.setOption(scatterPlotOptionValue);
  }

  // 处理散点图圈选事件
  function __handleScatterPlotBrushSelected(e: any) {
    const { dataIndex } = e.batch[0].selected[0];
    if (dataIndex.length === 0) {
      return;
    }

    const {
      tableChartOptionDatas,
      tableChartOptionValue,
      scatterPlotOptionDatas,
      scatterPlotOptionValue,
    } = __getEchartsOption();

    // 散点图
    const selectedNames: string[] = [];
    dataIndex.forEach((index: number) => {
      selectedNames.push(scatterPlotOptionDatas[index].name);
      __setSelectedData(scatterPlotOptionDatas[index]);
    });

    // 矩阵
    selectedNames.forEach((name: string) => {
      const findTableChartData = tableChartOptionDatas.find(
        (item: any) => item.name === name
      );
      __setSelectedData(findTableChartData);
    });

    __echartScatterPlot.setOption(scatterPlotOptionValue);
    __echartTableChart.setOption(tableChartOptionValue);
  }

  // 处理图表矩阵圈选事件
  function __handleTableChartBrushSelected(e: any) {
    const { dataIndex } = e.batch[0].selected[0];
    if (dataIndex.length === 0) {
      return;
    }

    const {
      tableChartOptionDatas,
      tableChartOptionValue,
      scatterPlotOptionDatas,
      scatterPlotOptionValue,
    } = __getEchartsOption();

    dataIndex.forEach((index: number) => {
      const findScatterPlotData = scatterPlotOptionDatas.find(
        (item: any) => item.name === tableChartOptionDatas[index].name
      );

      if (findScatterPlotData) {
        __setSelectedData(tableChartOptionDatas[index]);
        __setSelectedData(findScatterPlotData);
      }
    });

    __echartScatterPlot.setOption(scatterPlotOptionValue);
    __echartTableChart.setOption(tableChartOptionValue);
  }

  // 移除所有选中
  function __removeAllSelected() {
    const {
      tableChartOptionValue,
      scatterPlotOptionValue,
      tableChartOptionDatas,
      scatterPlotOptionDatas,
    } = __getEchartsOption();

    function removeStyle(item: any) {
      const newItem = { ...item };

      newItem.selected = false;

      if (!newItem.itemStyle) {
        newItem.itemStyle = {};
      }
      newItem.itemStyle.borderWidth = 0;
      newItem.itemStyle.shadowBlur = 0;

      return newItem;
    }

    const resetTableChartDatas = tableChartOptionDatas.map(removeStyle);
    const resetScatterPlotDatas = scatterPlotOptionDatas.map(removeStyle);

    (tableChartOptionValue.series as any)[0].data = resetTableChartDatas;
    (scatterPlotOptionValue.series as any)[0].data = resetScatterPlotDatas;

    __echartTableChart.setOption(tableChartOptionValue);
    __echartScatterPlot.setOption(scatterPlotOptionValue);
  }

  // 处理右击菜单/打开菜单选项
  function __handleRightClickMenu(e: any) {
    if (e.event) {
      e.event.preventDefault();
    }

    const { clientX, clientY } = e.event;
    __showContextMenu(clientX, clientY);
  }

  // 保存孔酶标仪原始数据
  function __saveData(find: any) {
    const { lists } = __kMeansLabels[selectedClasses.value];
    const [updateX, updateY] = find.value;
    const newLists = deepCopy(lists);

    // 查找哪个点发生改变了
    for (const [key, item] of Object.entries(newLists)) {
      for (const [index, value] of (item as number[][]).entries()) {
        const [x, y] = value;
        if (isFloatEqual(x, updateX) && isFloatEqual(y, updateY)) {
          if (!lists[find.key]) {
            lists[find.key] = [];
          }
          lists[find.key].push(value);

          // 移除原来的数据
          lists[key].splice(index, 1);

          return true;
        }
      }
    }

    return false;
  }

  // 删除孔酶标仪原始数据
  function __deleteData(data: any, find: any) {
    const { lists } = __kMeansLabels[selectedClasses.value];
    const [updateX, updateY] = find.value;
    const newLists = deepCopy(lists);

    // 查找哪个点发生改变了
    label: for (const [key, item] of Object.entries(newLists)) {
      for (const [index, value] of (item as number[][]).entries()) {
        const [x, y] = value;
        if (isFloatEqual(x, updateX) && isFloatEqual(y, updateY)) {
          lists[key].splice(index, 1);
          break label;
        }
      }
    }

    // 查找待删除的index
    const deleteIndex = data.findIndex((item: any) => {
      const [x, y] = item.value;
      if (isFloatEqual(x, updateX) && isFloatEqual(y, updateY)) {
        return true;
      }
      return false;
    });

    if (deleteIndex !== -1) {
      data.splice(deleteIndex, 1);
      __result[selectedClasses.value].splice(deleteIndex, 1);
    }

    return false;
  }

  // 更新数值
  function __updateEchartData(index: number) {
    // 获取选中的数据
    const {
      tableChartOptionDatas,
      tableChartOptionValue,
      scatterPlotOptionDatas,
      scatterPlotOptionValue,
    } = __getEchartsOption();

    const list: typeof ENZYME_POINT_STYLES | typeof FLUORESCENCE_POINT_STYLES =
      __dataAnalysisType === "ENZYME"
        ? ENZYME_POINT_STYLES
        : FLUORESCENCE_POINT_STYLES;
    const deleteKey = list[list.length - 1].key;
    const deleteList: any[] = [];

    function updateData(item: any) {
      item.key = list[index].key;

      const style = __getStyle(item.key);

      item.itemStyle = style?.itemStyle;
      item.symbol = style?.symbol;

      if (item.key === deleteKey) {
        item.emphasis = {
          scale: false,
        };
      }
    }

    tableChartOptionDatas.forEach((item: any) => {
      if (!item.selected) {
        return;
      }
      updateData(item);
    });
    scatterPlotOptionDatas.forEach((item: any) => {
      if (!item.selected) {
        return;
      }
      updateData(item);

      if (item.key === deleteKey) {
        deleteList.push(item);
      } else {
        __saveData(item);
      }
    });

    // 删除数据
    deleteList.forEach((item) => {
      __deleteData(scatterPlotOptionDatas, item);
    });

    if (deleteList.length > 0) {
      __renderEchart(selectedClasses.value);
    } else {
      __echartTableChart.setOption(tableChartOptionValue);
      __echartScatterPlot.setOption(scatterPlotOptionValue);
    }

    __removeAllSelected();
  }

  // 显示菜单
  function __showContextMenu(x: number, y: number) {
    __removeContextMenu();

    __contextMenu.value = document.createElement("div");
    __contextMenu.value.id = MENU_ID;
    __contextMenu.value.style.position = "fixed";
    __contextMenu.value.style.top = `${y}px`;
    __contextMenu.value.style.left = `${x}px`;

    function generateLi() {
      let html = "";
      const list =
        __dataAnalysisType === "ENZYME"
          ? ENZYME_POINT_STYLES
          : FLUORESCENCE_POINT_STYLES;

      list.forEach((item) => {
        html += `<li>${item.key}</li>`;
      });
      return html;
    }

    __contextMenu.value.innerHTML = `<ul>${generateLi()}</ul>`;

    // 获取所有生成的 li 元素
    const liElements = __contextMenu.value.querySelectorAll("li");

    // 为每个 li 元素添加点击事件
    liElements.forEach((li, i) => {
      li.addEventListener("click", () => {
        __updateEchartData(i);
      });
    });

    document.body.appendChild(__contextMenu.value);
  }

  // 移除菜单
  function __removeContextMenu() {
    if (__contextMenu.value) {
      __contextMenu.value.remove();
    }
  }

  // 返回
  return {
    selectedFile,
    refScatterPlot,
    refTableChart,
    dataAnalysisType,
    classesDatas,
    selectedClasses,
    downloadLoading,
    showReuslt,

    handleSelectFile,
    handleSubmit,
    onDownloadData,
  };
}
