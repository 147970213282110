/* eslint-disable @typescript-eslint/no-explicit-any */
import * as XLSX from "xlsx";

import { isFloatEqual, parseXlsxPostion } from "@/utils";

import type { KMeansResponse } from "@/service/ClusteringService";
import http from "@/http";

interface EnzymeParams {
  [key: string]: KMeansResponse;
}

interface KeyValue {
  [key: string]: any;
}

// SNPDecoder服务管理
export default class SNPDecoderService {
  private __headers = [
    "Postion",
    "Sample_name",
    "Markers",
    "Genotype",
    "FAM",
    "HEX/VIC",
    "ROX",
    "FR",
    "HR",
  ];

  private __filename = "";
  private __type = "";
  private __table: string[][] = []; // 表格数据

  constructor(filename: string, type = "酶标仪") {
    this.__filename = filename.split(".")[0];
    this.__type = type;
  }

  // 下载数据
  downloadData(results: KeyValue, labels: EnzymeParams) {
    for (const [markers, list] of Object.entries(results)) {
      for (const rValue of list.values()) {
        this.__handleLabelData(markers, rValue, labels);
      }
    }

    this.__table.sort(this.__dataSort);
    this.__table.unshift(this.__headers);

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet(this.__table);
    ws["!cols"] = [
      { wch: 8 },
      { wch: 13 },
      { wch: 13 },
      { wch: 30 },
      { wch: 8 },
      { wch: 12 },
      { wch: 8 },
      { wch: 20 },
      { wch: 20 },
    ];
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    const excelResult = XLSX.write(wb, {
      bookType: "xlsx",
      type: "array",
    }) as ArrayBuffer;

    this.__handleDownloadFile(excelResult);
    this.__handleUploadFile(excelResult);
  }

  // 处理文件下载
  private __handleDownloadFile(binaryData: ArrayBuffer) {
    const blob = new Blob([new Uint8Array(binaryData)], {
      type: "application/octet-stream",
    });

    // 创建下载链接
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `${this.__filename}.xlsx`;

    // 模拟点击下载链接
    link.click();

    // 释放资源
    window.URL.revokeObjectURL(link.href);
  }

  // 处理上传文件到后端
  private __handleUploadFile(binaryData: ArrayBuffer) {
    const blob = new Blob([new Uint8Array(binaryData)], {
      type: "application/octet-stream",
    });
    const formData = new FormData();

    formData.append("file", blob, `${this.__filename}.xlsx`);
    formData.append("data_type", this.__type);

    http.post("/api/file/upload_snp_decoder_file", formData, {
      "Content-Type": "multipart/form-data",
    });
  }

  // 处理孔酶标数据
  private __handleLabelData(markers: string, data: any, labels: EnzymeParams) {
    const { x, y, radio, Sample_name, name } = data;
    const position = parseXlsxPostion(name);
    if (Number(position.x) < 10) {
      position.x = position.x.padStart(2, "0");
    }

    let xValue = Number(x);
    let yValue = Number(y);
    const radioValue = Number(radio);

    if (!isNaN(radioValue) && radioValue !== 0) {
      xValue = xValue / radioValue;
      yValue = yValue / radioValue;
    }

    const { lists } = labels[markers];
    for (const [genotype, lItem] of Object.entries(lists)) {
      for (const lValue of lItem.values()) {
        const [xl, yl] = lValue;

        if (!isFloatEqual(xl, xValue) || !isFloatEqual(yl, yValue)) {
          continue;
        }

        this.__table.push([
          `${position.y}${position.x}`,
          Sample_name,
          markers,
          genotype,
          x,
          y,
          radio,
          String(xl),
          String(yl),
        ]);
      }
    }
  }

  // 数据排序
  private __dataSort(a: string[], b: string[]): number {
    // 提取 A-Z 部分
    const partA = (a[0].match(/[A-Z]+/) || [])[0] || "";
    const partB = (b[0].match(/[A-Z]+/) || [])[0] || "";

    // 提取 1-384 部分并转为数字
    const numA = Number(a[0].match(/\d+/)) || 0;
    const numB = Number(b[0].match(/\d+/)) || 0;

    // 比较字母部分
    if (partA !== partB) {
      return partA.localeCompare(partB);
    }

    // 字母相同则比较数字部分
    return numA - numB;
  }
}
