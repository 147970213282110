import { ElMessage } from "element-plus";

import http from "@/http";
import router from "@/router";
import { gLoading, getUrlParam } from "@/utils";
import { SESSION_AUTHORIZATION_KEY } from "@/utils/global";

export interface LoginDao {
  username: string;
  password: string;
}

interface LoginDto {
  token: string;
}

// 退出登录
export function logout() {
  sessionStorage.removeItem(SESSION_AUTHORIZATION_KEY);
  router.replace("/");
}

// 判断是否登录
export function isLogin() {
  const hasAuthorication = sessionStorage.getItem(SESSION_AUTHORIZATION_KEY);
  if (hasAuthorication) {
    return true;
  }
  return false;
}

// 登录类
export default class LoginService {
  url: string;
  data: LoginDao;

  constructor(data: LoginDao) {
    this.data = data;
    this.url = "/api/login/index";
  }

  login() {
    if (!this.validate()) {
      return;
    }
    const loading = gLoading("登录中...");
    http
      .post<LoginDto>(this.url, this.data)
      .then((res) => {
        if (res.code === 200) {
          const redirect = getUrlParam("redirect");
          ElMessage.success("登录成功");
          sessionStorage.setItem(SESSION_AUTHORIZATION_KEY, res.data.token);
          if (redirect) {
            router.push(redirect);
          } else {
            router.push("/");
          }
        } else {
          ElMessage.error(res.message);
        }
      })
      .finally(() => {
        loading.close();
      });
  }

  private validate() {
    if (!this.data.username) {
      ElMessage.error("请输入用户昵称");
      return false;
    }
    if (!this.data.password) {
      ElMessage.error("请输入用户密码");
      return false;
    }
    return true;
  }
}
