<template>
  <footer>
    <div class="contact"></div>
    <div class="copyright">
      <p>© Copyright©2023 广州固德生物技术有限公司版权所有</p>
      <a href="https://beian.miit.gov.cn/" target="_blank">
        粤ICP备18115527号-1
      </a>
      <img src="@/assets/images/ga.png" />
      <p>粤ICP备18115527号-1</p>
    </div>
  </footer>
</template>

<style lang="less">
footer {
  height: 48px;
  background-color: @theme-color;
  color: white;
  text-align: center;
  font-weight: 500;

  .copyright {
    display: flex;
    font-size: 14px;
    align-items: center;
    justify-content: center;
    a {
      color: #afeeee;
      font-weight: 500;
      margin: 0 5px;
    }
  }
}
</style>
