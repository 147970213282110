<template>
  <div class="contact-us">
    <wapper-component title="Contact us" :background="Bg" />

    <div class="container">
      <div class="top">
        <div id="map" class="map"></div>
        <div class="wechat">
          <h2>关于我们</h2>
          <p>
            广州固德生物技术有限公司是一家以生物试剂，科研服务为主营方向的高科技生物技术公司。
          </p>
          <p>
            广州固德生物技术有限公司成立于2015年，是一家产学研相结合，集试剂仪器研发、生产、销售及技术服务为一体的生物高科技企业。涉足于生命科学和大健康领域。
          </p>
          <p>
            公司主要从事分子生物学、细胞生物学、免疫学、动物学等相关领域的技术服务和仪器开发。公司已经研制出荧光定量qPCR试剂，SNP分析KASP试剂，蓝光切胶拍摄仪，二代测序分析软件等多个产品，在农业，医疗等领域有广发的客户源，并已经在全国包括北京、上海、广州、兰州、武汉等十多个城市形成分销网络。
          </p>
          <p>
            公司科研团队具有丰富的科研经验，立足于生物医学领域，建立了实验设计和评估、动物实验、细胞实验、基因水平实验、蛋白质水平实验、整体课题协作服务等平台，为客户提供最佳的实验设计服务和专业的实验解决方案，帮助客户缩短科研周期、节省试验成本，使客户的科学研究具有持续性。
          </p>
        </div>
      </div>

      <h2>联系方式</h2>
      <el-divider />
      <div class="contact-info">广州固德生物技术有限公司</div>
      <div class="contact-info">统一社会信用代码：91440106MA59B8HH3E</div>
      <div class="contact-info">
        开户行：兴业银行东山支行 391060100100145548
      </div>
      <div class="contact-info">
        联系地址：广州市天河区高科路36号医疗器械工程中心孵化器401
      </div>
      <div class="contact-info">电话：15622279127 陈强</div>
      <div class="contact-info">QQ：2679855349</div>
      <div class="contact-info">邮件地址：support@goodbtk.com</div>
      <div class="last-div"></div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onMounted } from "vue";
import { ElDivider } from "element-plus";
import WapperComponent from "@/components/WapperComponent.vue";
import Bg from "@/assets/images/contact.jpg";

onMounted(() => {
  initMap();
});

function initMap() {
  const bmap = new window.BMap.Map("map");
  const point = new window.BMap.Point(113.416014, 23.193094);
  bmap.centerAndZoom(point, 18);
  bmap.addControl(new window.BMap.NavigationControl());

  // 添加标注点
  const marker = new window.BMap.Marker(point);
  bmap.addOverlay(marker);

  // 创建信息窗口
  const infoWindow = new window.BMap.InfoWindow(
    "<div>联系地址：<br/>广州市天河区高科路36号医疗器械工程中心孵化器401</div>"
  );

  // 添加标注点点击事件
  marker.addEventListener("click", function () {
    // 在点击事件中打开信息窗口
    bmap.openInfoWindow(infoWindow, point);
  });
}
</script>

<style lang="less" scoped>
.contact-us {
  .container {
    width: @app-width;
    margin: 0 auto;
    .top {
      display: flex;
      gap: 20px;
      margin: 30px 0;
      div {
        background: #f1f5f7;
        height: 648px;
      }
      .map {
        flex: 2;
      }
      .wechat {
        flex: 1;
        padding: 20px;
        box-sizing: border-box;
        p {
          text-align: justify;
          text-indent: 2em;
          line-height: 26px;
        }
      }
    }
    h2 {
      font-weight: 500;
    }
    .contact-info {
      margin-bottom: 10px;
      font-size: 18px;
    }
    .last-div {
      margin-bottom: 50px;
    }
  }
}
</style>
