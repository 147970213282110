<template>
  <div class="login">
    <h1>用户登录</h1>

    <form class="snp-form">
      <div class="snp-item">
        <div class="snp-label">用户名称：</div>
        <div class="snp-input">
          <input
            v-model="data.username"
            placeholder="请输入用户名称或手机号码登录"
          />
        </div>
      </div>

      <div class="snp-item">
        <div class="snp-label">用户密码：</div>
        <div class="snp-input">
          <input
            v-model="data.password"
            type="password"
            placeholder="请输入登录密码"
          />
        </div>
      </div>

      <div class="snp-item">
        <div class="snp-label"></div>
        <div class="snp-btn-group">
          <div class="submit-btn" @click="onLogin">立即登录</div>
          <div class="text-btn" @click="toForgetPassword">忘记密码</div>
        </div>
      </div>

      <div class="snp-item snp-item-register">
        <div class="snp-label"></div>
        <div class="snp-btn-group">
          没有账号？
          <div class="text-btn" @click="toRegister">去注册</div>
        </div>
      </div>
    </form>
  </div>
</template>

<script lang="ts" setup>
import { useRouter } from "vue-router";

import LoginService from "@/service/LoginService";
import { reactive } from "vue";

const router = useRouter();
const data = reactive({
  username: "",
  password: "",
});

function toForgetPassword() {
  router.push("/AccountForgetPassword");
}

function toRegister() {
  router.push("/AccountRegister");
}

function onLogin() {
  const loginService = new LoginService(data);
  loginService.login();
}
</script>

<style lang="less" scoped>
.login {
  width: @app-width;
  margin: 0 auto;
  padding: 50px 0;
  text-align: center;
  h1 {
    margin-bottom: 50px;
    font-weight: 400;
    font-size: 42px;
  }
  .snp-item-register {
    .snp-btn-group {
      gap: 0;
      margin: -30px 0 0;
    }
  }
}
</style>
