<template>
  <div class="home">
    <wapper-component title="Welcome to Goodbtk" :background="Bg" />

    <div class="menu">
      <div v-for="item of menus" :key="item.title" class="item">
        <div class="title">{{ item.title }}</div>
        <div class="info">{{ item.content }}</div>
        <div class="enter" @click="onEnter(item.url)">
          <p>ENTER</p>
          <CaretRight />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { reactive } from "vue";
import { CaretRight } from "@element-plus/icons-vue";
import { useRouter } from "vue-router";

import WapperComponent from "@/components/WapperComponent.vue";
import Bg from "@/assets/images/homebg.jpg";

const router = useRouter();

const menus = reactive([
  {
    title: "SNP Primer",
    content: `
      SNP引物设计工具。
      根据提供序列、SNP基因型及SNP位置信息，
      自动批量设计FLU-ARMS标记引物。
    `,
    url: "/SNPPrimer",
  },
  {
    title: "SNP Decoder",
    content: `
      SNP自动分型工具。
      可解析酶标仪及道格拉斯系列仪器荧光扫描文件，
      自动进行基因分型。
    `,
    url: "/SNPDecoder",
  },
  {
    title: "Blast",
    content: "序列比对工具（外链NCBI）",
    url: "https://blast.ncbi.nlm.nih.gov/Blast.cgi?PROGRAM=blastn&PAGE_TYPE=BlastSearch&LINK_LOC=blasthome",
  },
]);

function onEnter(url: string) {
  if (url.includes("http")) {
    window.open(url, "_blank");
  } else {
    router.push(url);
  }
}
</script>

<style lang="less" scoped>
.home {
  h2 {
    font-size: 45px;
    text-align: center;
    font-weight: 400;
    padding: 60px 0;
    color: @tcolor;
  }

  .menu {
    margin: 30px auto;
    width: @app-width;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 30px;
    .item {
      padding: 15px 25px;
      background-color: rgba(234, 223, 240, 0.16);
      border: 1px solid #edece8;
      transition: all 0.3s ease-in;
      color: @theme-color;
      .title {
        text-align: center;
        padding: 20px 0px;
        font-size: 29px;
      }
      .info {
        height: 66px;
        text-align: center;
      }
      .enter {
        width: 100px;
        height: 50px;
        color: @theme-color;
        transition: all 0.3s ease-in;
        border: 1px solid @theme-color;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 30px auto;
        cursor: pointer;
        &:hover {
          background-color: @theme-color;
          color: white;
        }
      }
      :deep(svg) {
        width: 1.2em;
        position: relative;
        bottom: 1px;
      }
      &:hover {
        box-shadow: 0 3px 6px 2px rgba(0, 0, 0, 0.12);
      }
    }
  }
}
</style>
