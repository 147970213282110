<template>
  <header>
    <div class="wrapper">
      <div class="logo" @click="onLogo">
        <div class="logo2">
          <img src="@/assets/logo2.png" />
        </div>
        <img class="logo1" src="@/assets/logo1.jpeg" />
      </div>
      <nav>
        <template v-if="username === ''">
          <router-link to="/AccountLogin">登录</router-link>
          <router-link to="/AccountRegister">注册</router-link>
        </template>
        <template v-else>
          <div class="user-info" @click="onLogout">
            {{ `当前用户：${username}` }}
          </div>
        </template>

        <div class="branch"></div>
        <router-link to="/">Home</router-link>
        <router-link to="/SNPPrimer">SNP Primer</router-link>
        <router-link to="/SNPDecoder">SNP Decoder</router-link>
        <li @click="onBlast">Blast</li>
        <router-link to="/ContactUS">Contact Us</router-link>
      </nav>
    </div>
  </header>
</template>

<script lang="ts" setup>
import { ElMessageBox } from "element-plus";
import { ref, watch } from "vue";
import { useRoute } from "vue-router";

import http from "@/http";
import { onBlast } from "@/utils";
import { isLogin, logout } from "@/service/LoginService";

interface UserInfoDto {
  username: string;
  phone: string;
  email: string;
  work: string;
}

const route = useRoute();
const username = ref("");

watch(route, () => {
  if (isLogin() && username.value === "") {
    getUserInfo();
  } else if (!isLogin()) {
    username.value = "";
  }
});

function getUserInfo() {
  http.get<UserInfoDto>("/api/user/info").then((res) => {
    if (res.code === 200) {
      username.value = res.data.username;
    }
  });
}

function onLogout() {
  ElMessageBox.confirm("确认退出账号吗?", "提示", {
    confirmButtonText: "确认",
    cancelButtonText: "取消",
  })
    .then(() => {
      logout();
      username.value = "";
    })
    .catch(() => null);
}

function onLogo() {
  window.open("http://www.goodbtk.com", "_target");
}
</script>

<style lang="less" scoped>
@height: 80px;
header {
  width: 100%;
  background-color: white;
  color: @theme-color;
  position: sticky;
  top: 0;
  z-index: 999;
  box-shadow: 0 0 3px @theme-color;

  .wrapper {
    height: @height;
    display: flex;
    align-items: center;
    width: 1200px;
    margin: 0 auto;
  }

  .logo {
    display: flex;
    flex-direction: column;
    gap: 5px;
    cursor: pointer;
    .logo2 {
      width: 129px;
      height: 19px;
      overflow: hidden;
      img {
        width: 100%;
        margin-top: -5px;
      }
    }
    .logo1 {
      width: 84px;
      height: 26px;
      margin-left: 12px;
    }
  }

  nav {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    a,
    li {
      color: @theme-color;
      text-decoration: none;
      font-size: 14px;
      padding: 10px 15px;
      cursor: pointer;
      font-weight: 500;
      transition: all 0.1s;
      &:hover {
        background-color: @active-color;
        color: white;
      }
    }
    .branch {
      width: 30px;
    }
  }

  .user-info {
    line-height: @height;
    cursor: pointer;
  }
}
</style>
