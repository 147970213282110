<template>
  <div class="register">
    <h1>用户注册</h1>

    <form class="snp-form">
      <div class="snp-item">
        <div class="snp-label require">用户名称：</div>
        <div class="snp-input">
          <input v-model="data.username" />
        </div>
      </div>

      <div class="snp-item">
        <div class="snp-label require">用户密码：</div>
        <div class="snp-input">
          <input v-model="data.password" type="password" />
        </div>
      </div>

      <div class="snp-item">
        <div class="snp-label require">电子邮件：</div>
        <div class="snp-input">
          <input v-model="data.email" />
        </div>
      </div>

      <div class="snp-item">
        <div class="snp-label require">工作单位：</div>
        <div class="snp-input">
          <input v-model="data.work" />
        </div>
      </div>

      <div class="snp-item">
        <div class="snp-label require">手机号码：</div>
        <div class="snp-input">
          <input v-model="data.phone" />
        </div>
      </div>

      <div class="snp-item">
        <div class="snp-label require">短信验证：</div>
        <div class="snp-input">
          <input v-model="data.code" />
          <div class="snp-get-code" @click="getCode">{{ codeText }}</div>
        </div>
      </div>

      <div class="snp-item">
        <div class="snp-label"></div>
        <div class="snp-btn-group">
          <div class="submit-btn" @click="handleSubmit">立即注册</div>
          <div class="text-btn" @click="toLogin">已有账号，去登录</div>
        </div>
      </div>
    </form>
  </div>
</template>

<script lang="ts" setup>
import { ref, reactive } from "vue";
import { useRouter } from "vue-router";
import { ElMessage } from "element-plus";

import http from "@/http";
import { gLoading } from "@/utils";

const router = useRouter();
const data = reactive({
  username: "",
  password: "",
  email: "",
  work: "",
  phone: "",
  code: "",
});
const DEFAULT_CODE_TEXT = "获取验证码";
const codeText = ref(DEFAULT_CODE_TEXT);
let codeTimer = 0;
let codeTime = 60;

function toLogin() {
  router.push("/AccountLogin");
}

function handleGetCode() {
  http.get(`/api/login/get_register_code?phone_number=${data.phone}`);
}

function getCode() {
  if (codeText.value !== DEFAULT_CODE_TEXT) {
    return;
  }
  if (!data.phone) {
    ElMessage.error("请输入您的手机号码");
    return false;
  }

  handleGetCode();
  clearInterval(codeTimer);
  codeText.value = `${codeTime}s`;
  codeTimer = window.setInterval(() => {
    codeTime--;
    if (codeTime <= 0) {
      codeText.value = DEFAULT_CODE_TEXT;
      clearInterval(codeTimer);
      codeTime = 60;
    } else {
      codeText.value = `${codeTime}s`;
    }
  }, 1000);
}

function registerValidate(): boolean {
  if (!data.username) {
    ElMessage.error("请输入用户昵称");
    return false;
  }
  if (!data.password) {
    ElMessage.error("请输入用户密码");
    return false;
  }
  if (!data.email) {
    ElMessage.error("请输入您的电子邮件");
    return false;
  }
  if (!data.work) {
    ElMessage.error("请输入您的工作单位");
    return false;
  }
  if (!data.phone) {
    ElMessage.error("请输入您的手机号码");
    return false;
  }
  if (!data.code) {
    ElMessage.error("请输入手机验证码");
    return false;
  }

  return true;
}

function handleSubmit() {
  if (!registerValidate()) {
    return;
  }

  const loading = gLoading("用户注册中...");
  http
    .post<typeof data>("/api/login/register", data)
    .then((res) => {
      if (res.code === 0) {
        ElMessage.error(res.message);
      } else {
        ElMessage.success("用户注册成功");
        router.push("/AccountLogin");
      }
    })
    .finally(() => {
      loading.close();
    });
}
</script>

<style lang="less" scoped>
.register {
  width: @app-width;
  margin: 0 auto;
  padding: 50px 0;
  text-align: center;
  h1 {
    margin-bottom: 50px;
    font-weight: 400;
    font-size: 42px;
  }
}
</style>
