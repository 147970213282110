<template>
  <div class="forget-password">
    <h1>重置密码</h1>

    <form class="snp-form">
      <div class="snp-item">
        <div class="snp-label">注册手机：</div>
        <div class="snp-input">
          <input v-model="data.phone" />
        </div>
      </div>

      <div class="snp-item">
        <div class="snp-label">重置密码：</div>
        <div class="snp-input">
          <input v-model="data.password" type="password" />
        </div>
      </div>

      <div class="snp-item">
        <div class="snp-label">确认密码：</div>
        <div class="snp-input">
          <input v-model="data.confirmPassword" type="password" />
        </div>
      </div>

      <div class="snp-item">
        <div class="snp-label">短信验证：</div>
        <div class="snp-input">
          <input v-model="data.code" />
          <div class="snp-get-code" @click="getCode">{{ codeText }}</div>
        </div>
      </div>

      <div class="snp-item">
        <div class="snp-label"></div>
        <div class="snp-btn-group">
          <div class="submit-btn" @click="onSubmit">重置密码</div>
          <div class="text-btn" @click="toLogin">去登录</div>
        </div>
      </div>
    </form>
  </div>
</template>

<script lang="ts" setup>
import { reactive, ref } from "vue";
import { ElMessage } from "element-plus";
import { useRouter } from "vue-router";

import http from "@/http";
import { gLoading } from "@/utils";

const router = useRouter();
const data = reactive({
  phone: "",
  password: "",
  code: "",
  confirmPassword: "",
});
const DEFAULT_CODE_TEXT = "获取验证码";
const codeText = ref(DEFAULT_CODE_TEXT);
let codeTimer = 0;
let codeTime = 60;

function toLogin() {
  router.push("/AccountLogin");
}

function resetValidate(): boolean {
  if (!data.phone) {
    ElMessage.error("请输入您的注册手机号");
    return false;
  }
  if (!data.password) {
    ElMessage.error("请输入重置密码");
    return false;
  }
  if (data.password !== data.confirmPassword) {
    ElMessage.error("两次密码输入不一致");
    return false;
  }
  if (!data.code) {
    ElMessage.error("请输入手机验证码");
    return false;
  }

  return true;
}

function onSubmit() {
  if (!resetValidate()) {
    return;
  }

  const loading = gLoading("重置密码中...");
  http
    .post("/api/login/modify_password", data)
    .then((res) => {
      if (res.code === 0) {
        ElMessage.error(res.message);
      } else {
        ElMessage.success("密码重置成功");
        router.push("/AccountLogin");
      }
    })
    .finally(() => {
      loading.close();
    });
}

function getCode() {
  if (codeText.value !== DEFAULT_CODE_TEXT) {
    return;
  }
  if (!data.phone) {
    ElMessage.error("请输入您的手机号码");
    return false;
  }

  clearInterval(codeTimer);
  http.get(`/api/login/get_modify_password_code?phone_number=${data.phone}`);
  codeText.value = `${codeTime}s`;
  codeTimer = window.setInterval(() => {
    codeTime--;
    if (codeTime <= 0) {
      codeText.value = DEFAULT_CODE_TEXT;
      codeTime = 60;
      clearInterval(codeTimer);
    } else {
      codeText.value = `${codeTime}s`;
    }
  }, 1000);
}
</script>

<style lang="less" scoped>
.forget-password {
  width: @app-width;
  margin: 0 auto;
  padding: 50px 0;
  text-align: center;
  h1 {
    margin-bottom: 50px;
    font-weight: 400;
    font-size: 42px;
  }
}
</style>
